#error-page {
  .error {
    &__page {
      margin-top: 120px;
      margin-right: auto;
      margin-bottom: 60px;
      margin-left: auto;

      .button {
        text-align: center;

        a.error-page__btn {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-common-line-heights-narrow-large);
          padding-top: 11px;
          padding-bottom: 11px;
          padding-left: 35px;
          padding-right: 35px;
          font-weight: var(--emu-common-font-weight-bold);

          &:hover {
            background-color: var(--emu-common-colors-transparent);
            color: var(--emu-semantic-colors-primary-100);
          }
        }
      }
    }

    &__title .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xxl);
      line-height: var(--emu-semantic-line-heights-narrow-xxl);
      color: var(--emu-semantic-colors-primary-50);
      margin-bottom: 30px;
    }

    &__text {
      margin-bottom: 30px;

      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-common-line-heights-wide-large);
        color: var(--emu-semantic-colors-primary-50);
      }
    }
  }
}
