/** Added style for global footer **/
footer {
  &.experiencefragment {
    z-index: calc(var(--emu-common-other-z-index-cookie-banner) + 2);
    position: relative;
    background: var(--emu-common-colors-white);
    padding-top: 42px;
  }

  .footer {
    &__links {
      padding-top: 24px;
      padding-bottom: 24px;

      ul {
        list-style: none;
        padding-left: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);

        @include mq('large') {
          display: flex;
          flex-direction: row;
          column-gap: 36px;
          flex-wrap: wrap;
          line-height: var(--emu-semantic-line-heights-narrow-xl);
        }

        > li {
          margin-bottom: var(--emu-common-spacing-none);

          a {
            display: inline-flex;
            gap: 11px;
            align-items: center;
            line-height: 30px;
            font-size: var(--emu-common-font-sizes-narrow-medium);
            font-weight: var(--emu-common-font-weight-regular);
            color: var(--emu-semantic-colors-primary-50);
            letter-spacing: 0.48px;
            text-decoration: none;

            &:hover,
            &:focus {
              font-weight: 700;
            }
          }

          img {
            max-width: 30px;
          }
        }
      }
    }

    &__wrapper-container {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      padding: var(--emu-common-spacing-none);
      padding-left: 22px;
      padding-right: 22px;

      .container--inner {
        @include mq('large') {
          display: flex;
          column-gap: 150px;
          align-items: center;
          margin: auto;
          padding: var(--emu-common-spacing-none);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-medium-grey-500);
          padding-bottom: 30px;
        }

        .aaaem-image svg {
          height: 20px;
          width: auto;
        }
      }

      .text {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          font-weight: var(--emu-common-font-weight-light);
          margin: var(--emu-common-spacing-none);
          color: var(--emu-semantic-colors-primary-50);
        }
      }
    }

    &__copyright-text {
      margin-top: 24px;
      margin-bottom: var(--emu-common-spacing-none);

      @include mq('large') {
        margin-top: var(--emu-common-spacing-small);
      }
    }
  }
}
