.isi-product-container {
  max-width: 100%;
  position: relative;
  overflow: auto;

  .aaaem-isi {
    &-banner {
      .isi-banner__banner-content {
        button {
          position: relative;
          padding-right: 20px;
          min-height: 22px;

          span {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            font-weight: var(--emu-common-font-weight-bold);
          }

          &::before,
          &::after {
            content: '';
            height: 11px;
            width: 3px; // to match the design.
            background-color: var(--emu-common-colors-black);
            position: absolute;
          }

          &[data-ref='button-show'] {
            &::before,
            &::after {
              right: 5px;
              top: 5px;
            }

            &::after {
              transform: rotate(90deg); // to match the design.
            }
          }

          &[data-ref='button-collapse'] {
            &::after {
              left: 50%;
              top: 50%;
            }

            &::after {
              transform: translate(-50%, -50%) rotate(90deg); // to match the design.
            }

            &::before {
              display: none;
            }
          }
        }
      }
    }

    &-container__isi-content {
      padding-right: 28px;
      padding-left: 28px;
      background-color: var(--emu-common-colors-white);
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-secondary-50);

      // content in the banner
      &[data-ref='content'] {
        .form__isi-title-section {
          display: none;
        }
      }

      .aaaem-text.text {
        p,
        h5 {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: normal;
          margin: var(--emu-common-spacing-none);
          margin-bottom: 10px;
        }

        a {
          color: var(--emu-common-colors-black);
          word-wrap: break-word;
        }

        sup {
          font-weight: var(--emu-common-font-weight-regular);
        }

        h5 {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          padding-top: 26px;
        }
      }

      > div:first-child {
        padding: var(--emu-common-spacing-none);
        padding-top: 21px;
      }

      .form__isi {
        &-title-section {
          &.text.aaaem-text p {
            font: var(--emu-component-ingredients-text-text-typography-wide);
            margin-bottom: 15px;
          }
        }

        &-first-section {
          position: relative;
          p:first-child {
            font-size: var(--emu-common-font-sizes-wide-medium);
            margin-bottom: 10px;
          }
        }

        &-product-section {
          ul {
            padding-left: var(--emu-common-spacing-medium);
            margin-top: var(--emu-common-spacing-none);
          }

          li {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        &-box-section {
          border-width: var(--emu-common-border-width-thin);
          border-style: solid;
          border-color: var(--emu-common-colors-black);
          padding: var(--emu-common-spacing-small);

          &.text {
            p {
              @include mq('large') {
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }

          ul {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &-last-section {
          margin-top: 60px;

          p {
            color: var(--emu-semantic-colors-primary-50);
          }
        }
      }
    }

    &-container__banner-title {
      display: block !important; // To override default style

      p {
        font-weight: var(--emu-common-font-weight-bold);
        margin: var(--emu-common-spacing-none);
        line-height: normal;
      }
    }
  }

  .isi-container {
    .aaaem-isi-container__isi-content {
      padding-right: 28px;
      padding-bottom: 40px;
      padding-left: 28px;
      height: 120px;
      border: var(--emu-common-border-width-none);

      div {
        padding: var(--emu-common-spacing-none);
      }

      &.js-expand {
        height: 156px; // to match the design.

        @include mq('large') {
          height: 270px;
        }
      }
    }
  }
}
