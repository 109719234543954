#account-update-request {
  .container-select {
    &__section {
      margin-top: 100px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 60px;

      @include mq('medium') {
        margin-bottom: 124px;
      }
    }

    &__heading-title p {
      font-size: 35px;
      line-height: normal;
      margin: var(--emu-common-spacing-none);
      display: inline;
      color: var(--emu-semantic-colors-primary-50);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xxxl);
        display: block;
      }
    }

    &__title {
      h2 {
        margin-bottom: 25px;
        margin-top: 60px;
        font-size: var(--emu-common-line-heights-narrow-xxl);
        line-height: normal;
        color: var(--emu-semantic-colors-primary-50);
      }

      &--spacing h2 {
        margin-bottom: 37px;
      }
    }

    &__text {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: normal;
        color: var(--emu-semantic-colors-primary-50);
        font-weight: var(--emu-common-font-weight-bold);
        margin-bottom: 60px;
      }
    }

    &__button {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: normal;
      padding: var(--emu-common-spacing-none);
      text-align: left;
      background-color: transparent;
      color: var(--emu-semantic-colors-primary-50);
      border-width: var(--emu-common-border-width-none);
      margin-bottom: 40px;

      &:focus {
        box-shadow: none;
      }

      &.aaaem-button:active {
        outline: none;
      }

      .cmp-button {
        &__text {
          // white-space: nowrap;
          padding-right: 20px;
          text-decoration: underline;
        }
      }

      &-container {
        margin: var(--emu-common-spacing-none);
        scroll-margin-top: 100px;
      }

      &--spacing {
        margin-left: 44px;
        margin-bottom: 60px;
      }
    }

    &__wrapper {
      margin: var(--emu-common-spacing-none);
    }

    &__container {
      display: none;
      scroll-margin-top: 100px;

      &.container-select__active {
        display: block;
      }

      .form-content {
        @include mq('medium') {
          max-width: 680px;
        }

        .cmp-form-text__help-block {
          display: none;
        }

        label.required:after,
        .options fieldset[data-required='true'] legend:after {
          content: '*';
        }

        > .text,
        .options {
          > div {
            padding-bottom: 20px;
            display: grid;
          }

          .container-select__text--spacing {
            margin-bottom: 14px;
          }

          .form-content__field {
            &--hide {
              display: none;
            }
          }
        }

        .text p,
        label,
        legend,
        label span {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: normal;
          color: var(--emu-common-colors-black);
        }

        label {
          margin-bottom: 11px;
        }

        .text,
        .options {
          p {
            margin: var(--emu-common-spacing-none);
            margin-bottom: 3px;
            width: 100%;

            b {
              color: var(--emu-semantic-colors-primary-50);
            }
          }

          input,
          select {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            height: 48px;
            background-clip: padding-box;
            background-color: var(--emu-common-colors-white);
            border: var(--emu-common-border-width-thin) solid
              var(--emu-semantic-colors-secondary-50);
            color: var(--emu-common-colors-black);
            display: block;
            padding: 12px;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
            width: 100%;

            &:focus {
              border: var(--emu-common-border-width-medium) solid
                var(--emu-semantic-colors-primary-50);
              border-radius: var(--emu-common-border-radius-xs);
            }
          }
        }

        .options {
          .cmp-form-options {
            &--drop-down {
              position: relative;

              &:after {
                border-bottom: var(--emu-common-border-width-thin) solid
                  var(--emu-semantic-colors-primary-50);
                border-right: var(--emu-common-border-width-thin) solid
                  var(--emu-semantic-colors-primary-50);
                content: '';
                display: block;
                height: 10px;
                position: absolute;
                right: 18px;
                top: calc(100% - 52px);
                transform: rotate(45deg);
                width: 10px;
              }

              select {
                -webkit-appearance: none;
                margin: var(--emu-common-spacing-none);
                text-overflow: ellipsis;
              }
            }
          }

          legend {
            font-weight: var(--emu-common-font-weight-bold);
            color: var(--emu-semantic-colors-primary-50);
          }

          .emu-form-radio,
          .emu-form-checkbox {
            margin: var(--emu-common-spacing-none);
            padding-bottom: 20px;

            &__label {
              column-gap: var(--emu-common-spacing-xs);
              margin-bottom: var(--emu-common-spacing-none);
              cursor: pointer;
            }

            &__input {
              background-color: var(--emu-common-colors-white);
              width: var(
                --emu-component-ingredients-radio-input-control-size-all-narrow
              );
              height: var(
                --emu-component-ingredients-radio-input-control-size-all-narrow
              );
              padding: var(--emu-common-spacing-none);
              margin-top: var(--emu-common-spacing-xxs);
              cursor: pointer;

              &:before {
                clip-path: none;
                background-color: var(
                  --emu-component-ingredients-radio-input-control-color-background-default-light
                );
                box-shadow: none;
                transform: none;
              }

              &:checked::before {
                background-color: var(
                  --emu-component-ingredients-radio-input-control-color-background-checked-light
                );
                border: var(
                    --emu-component-ingredients-radio-input-control-border-width-narrow
                  )
                  solid var(--emu-common-colors-dark-grey-100);
                transform: scale(1.2);
              }

              &:focus {
                border: var(
                    --emu-component-ingredients-radio-input-control-border-width-narrow
                  )
                  solid var(--emu-common-colors-dark-grey-100);
                border-radius: var(--emu-common-border-radius-none);
              }
            }
          }

          .emu-form-radio {
            &__input {
              &:before,
              &:focus {
                border-radius: var(
                  --emu-component-ingredients-radio-input-control-border-radius-narrow
                );
              }
            }
          }
        }

        .has-error {
          &.hide-error {
            .error-message {
              display: none;
            }
          }
        }

        p.error-message,
        p.emu-form-checkbox__error-text,
        p.emu-form-radio__error-text,
        p.emu-form-text__error-text,
        p.emu-form-dropdown__error-text {
          color: var(--emu-semantic-colors-error-50);
          margin-top: var(--emu-common-spacing-small);
          font-size: var(--emu-semantic-font-sizes-narrow-large);
        }

        .container-select__checkbox {
          &--with-bg {
            display: flex;
            flex-direction: row;
            gap: 12px;
            flex-wrap: wrap;
            padding: 12px;
            border: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-dark-grey-100);
            background-color: var(--emu-common-colors-white);

            p.error-message {
              flex-basis: 100%;
              margin-bottom: var(--emu-common-spacing-none);
              font-size: 16px;
            }

            &:has(.emu-form-checkbox__legend) {
              position: relative;
              margin-top: 30px;
              margin-bottom: 20px;
            }

            span {
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: normal;
              color: var(--emu-semantic-colors-primary-50);
            }

            legend.emu-form-checkbox__legend {
              font-size: var(--emu-common-font-sizes-wide-medium);
              line-height: normal;
              color: var(--emu-common-colors-black);
              font-weight: var(--emu-common-font-weight-regular);
              margin-bottom: var(--emu-common-spacing-none);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              transform: translateY(-100%) translateY(-11px);
            }

            .emu-form-checkbox__label {
              margin: var(--emu-common-spacing-none);
            }

            &.emu-form-checkbox {
              // specificity is needed to override some styles
              legend.emu-form-checkbox__legend.emu-form-checkbox__legend.emu-form-checkbox__legend {
                font-weight: var(--emu-common-font-weight-regular);
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }

    &__hidden {
      display: none;
    }
  }

  .button-container {
    flex-direction: column;
    display: flex;
    gap: 20px;
    margin-top: 20px;

    @include mq('medium') {
      flex-direction: row;
    }

    button {
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: normal;
      font-weight: var(--emu-common-font-weight-bold);
      color: var(--emu-semantic-colors-actions-primary-light);
      padding-top: 11px;
      padding-bottom: 11px;
      padding-right: 30px;
      padding-left: 30px;
      background-color: var(--emu-common-colors-transparent);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-100);
      width: 100%;
      justify-content: center;

      &.aaaem-button:active {
        outline: none;
      }

      &.cmp-form-button:active,
      &.cmp-form-button:hover {
        outline: none;
        background-color: var(--emu-semantic-colors-actions-primary-light);
        color: var(--emu-common-colors-white);
      }

      &.aaaem-button__primary-filled {
        color: var(--emu-semantic-colors-actions-primary-light);
        border-width: var(--emu-common-border-width-none);

        &:hover,
        &:active {
          color: var(--emu-common-colors-black);
        }
      }

      @include mq('medium') {
        width: auto;
        justify-content: left;
      }
    }
  }

  .js-response-success {
    display: block;
    border: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-black);

    h3 {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: normal;
      color: var(--emu-semantic-colors-primary-50);
      text-align: center;
    }

    .cmp-ajax-form-success {
      display: block;
    }

    form {
      display: none;
    }
  }

  .js-response-error .cmp-ajax-form-error {
    display: block;
  }

  .emu-form__error-message {
    padding: 10px;
    border: var(--emu-common-border-width-thin) solid currentColor;
  }

  .ajaxContainer {
    &.js-response-errors {
      .emu-form__error-message {
        display: block;
      }
    }
  }
}

.container-select__container {
  @include aem-editor-view() {
    display: block !important;
  }
}
