div.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);

  .cmp-container-header {
    height: 86px;
    background: var(--emu-common-colors-white);
    display: flex;
    margin: var(--emu-common-spacing-none) auto;
    align-items: center;
    max-width: 100%;
    padding-left: 22px;
    padding-right: 22px;
  }
  .logo-primary-variant,
  .logo-primary,
  .header__search {
    display: none;
  }
}
